import React, { useRef, useState } from 'react'
import { graphql } from 'gatsby'

import { Seo } from '../components/base'
import {
  BlockContent,
  LayoutBackground,
  MainLeafIcon,
  SocialSharing,
  Button,
  BioDivider,
  BioNoResults
} from '../components/shared'
import { Layout, Search } from '../components'
import { DefaultApplication } from '../components/jobs/DefaultApplication'
import { BioButton } from '../components/shared'

const CareerPage = ({ data, pageContext, location }) => {
  const {
    filterButtonText,
    noSearchResultsText,
    applicationCardHeadline,
    applicationCardText
  } = data.sanityCareerPage
  const careerContext = pageContext.careerContext || 1

  const {
    id,
    title,
    altTitle,
    image,
    areaOfOperations,
    introductionText,
    _rawTask,
    _rawProfile,
    _rawOffering,
    _rawDescription
  } = data.sanityJobType

  const vacancies = data?.sanityVacancies?.nodes?.filter(
    vacancy => vacancy.market
  )
  const filteredVacancies = vacancies?.filter(vacancy => vacancy?.contact)
  const [searchItems, setSearchItems] = useState(location?.state?.searchItems)
  const searchResultRef = useRef()
  const languages = { pageContext }

  return (
    <Layout translations={languages}>
      <Seo
        title={altTitle || title}
        description={
          introductionText || `Wir suchen Sie als ${altTitle || title}`
        }
        image={image?.image?.asset?.url}
      />
      <div className="md:mx-auto w-full">
        <section className="md:mx-auto">
          <h1 className="text-center mb-12" data-testid="page-title">{`${
            introductionText || 'Wir suchen Sie als'
          } ${altTitle || title}`}</h1>
          {(image && (
            <img
              className="h-64 md:h-140 w-full object-cover rounded-md"
              src={image?.image?.asset?.url}
              alt={image?.alt}
            />
          )) || <BioDivider />}
          <div className="flex flex-wrap justify-between md:mb-14 mt-4">
            <ul
              data-testid="information-items-list"
              className="flex flex-wrap md:w-5/6 list-none">
              <InformationItem
                title="Arbeitszeit"
                text={getTypesOfEmployment(filteredVacancies)}
              />
              {areaOfOperations && (
                <InformationItem
                  title="Tätigkeitsbereich"
                  text={areaOfOperations?.title}
                />
              )}
            </ul>
          </div>
        </section>
        <LayoutBackground>
          <div className="divide-y divide-white -mt-7">
            <section className="flex justify-between flex-wrap mb-16 md:mb-20 md:mx-auto">
              {_rawDescription && (
                <div className="w-full md:max-w-750 m-auto  mb-0 md:mb-10">
                  <h1 className="text-2xl  pb-8 pt-0 text-center ">
                    Karriere mit Perspektive
                  </h1>
                  <BlockContent
                    data={_rawDescription}
                    className="w-full text-lg text-left"
                  />
                </div>
              )}

              <BlockContent
                data={_rawTask}
                className="block-content-styles md:w-1/2-grid-lg-gap mt-10"
              />
              <BlockContent
                data={_rawProfile}
                className="block-content-styles md:w-1/2-grid-lg-gap mt-10"
              />
              <BlockContent
                data={_rawOffering}
                className="block-content-styles md:w-1/2-grid-lg-gap mt-10"
              />
            </section>
            <section
              ref={searchResultRef}
              className="py-20 md:py-32 w-full relative">
              <div className="mb-14 md:mb-24">
                <h1 className="text-center text-xl md:text-3xl uppercase">{`Stellenangebote für ${
                  altTitle || title
                }`}</h1>
              </div>
              <Search
                showToTopButton
                noInitialRender
                index="vacancy"
                initialItems={filteredVacancies}
                count={filteredVacancies?.length}
                filterButtonText={filterButtonText}
                jobTypeId={id}
                searchListHeight={searchResultRef?.current?.offsetHeight}
                initialSearchItems={searchItems}
                setSearchItems={setSearchItems}
                careerContext={careerContext}
                withLocation
                hideFilterOnMobile={careerContext == 2}>
                <VacancyList
                  noSearchResultsText={noSearchResultsText}
                  data-testid="vacancy-list"
                  items={filteredVacancies}
                  jobTypeTitle={altTitle || title}
                  applicationCardHeadline={applicationCardHeadline}
                  applicationCardText={applicationCardText}
                />
              </Search>
            </section>
          </div>
        </LayoutBackground>
        <SocialSharing link={location} />
      </div>
    </Layout>
  )
}

const InformationItem = ({ title, icon, text }) => {
  return (
    text && (
      <li className="w-2/5 md:w-1/4 flex items-center md:items-start mr-10 md:mr-4 mb-8 md:mb-0">
        <div className="md:w-auto">
          <span className="w-auto text-brown">{icon}</span>
        </div>
        <div className="leading-tight">
          <legend className="text-xs uppercase tracking-widest">{title}</legend>
          <div className="font-semibold text-md" data-testid="information-item">
            {text}
          </div>
        </div>
      </li>
    )
  )
}

const VacancyList = ({
  items,
  jobTypeTitle,
  _rawNoSearchResultsText,
  applicationCardHeadline,
  applicationCardText
}) => {
  return (
    <>
      {!items.length && (
        <>
          <BioNoResults content={_rawNoSearchResultsText} />
          <DefaultApplication
            applicationCardHeadline={applicationCardHeadline}
            applicationCardText={applicationCardText}
          />
        </>
      )}
      <ul className="md:w-full list-none">
        {items &&
          items.map((vacancy, index) => {
            return (
              <VacancyItem
                className=""
                key={index}
                jobTypeTitle={jobTypeTitle}
                vacancyId={vacancy.id}
                title={vacancy.title}
                contact={vacancy.contact}
                typeOfEmployment={vacancy.typeOfEmployment}
                market={vacancy.market}
              />
            )
          })}
      </ul>
    </>
  )
}

const VacancyItem = ({
  vacancyId,
  typeOfEmployment,
  contact,
  market,
  jobTypeTitle
}) => {
  const contactLastName = contact?.lastName || ''
  const contactFirstName = contact?.firstName || ''
  return (
    <li
      className="md:w-full md:items-start md:mr-4 mb-8 px-10 py-8 bg-white rounded-md"
      data-testid="vacancy-test-data">
      <div className="w-full md:w-auto text-base">
        <div>
          <h3
            className="row-span-full font-bold text-base leading-8"
            data-testid="market-name-test">
            {market?.name || ''}
          </h3>
        </div>
        <span className="row-span-full text-base leading-8">
          {jobTypeTitle}
        </span>
      </div>
      <div className="divide-y md:flex items-start divide-beige md:divide-y-0">
        <div className="mt-4 py-6 text-sm md:w-10/12 md:flex items-center md:items-start md:mr-4 md:py-0">
          <div className="w-full mb-4 md:mb-0 md:w-1/3">
            {market && (
              <>
                <div>
                  <span className="w-auto font-semibold text-md">{`Beschäftigungsort`}</span>
                </div>
                <span className="w-auto text-brown block">{market.name}</span>
                <span className="w-auto text-brown block">
                  {market?.address?.street || market['address.street']}
                </span>
                <span className="w-auto text-brown block">
                  {`${market?.address?.zip || market['address.zip']} ${
                    market?.address?.city || market['address.city']
                  }`}
                </span>
              </>
            )}
          </div>
          <div className="w-full mb-4 md:mb-0 md:w-1/4">
            <div>
              <span className="w-auto font-semibold text-md">{`Beschäftigungsart`}</span>
            </div>
            <span className="w-auto text-brown">
              {typeOfEmployment.join(', ')}
            </span>
          </div>
          <div className="w-full mb-4 md:mb-0 md:w-2/5 flex items-center md:items-start">
            <div className="w-full md:w-3/4">
              <span className="w-auto font-semibold block text-md">
                Ihr*e Ansprechpartner*in
              </span>
              <span className="w-auto text-brown block">{`${contactFirstName} ${contactLastName}`}</span>
              <span className="w-auto text-brown block">
                {contact?.phone || ''}
              </span>
              <a
                href={`mailto:${contact?.email}`}
                className="w-auto text-green font-semibold block">
                {contact?.email}
              </a>
            </div>
            <span className="w-40 md:text-justify md:order-first md:mr-6">
              {((contact?.image?.image?.asset?.url || contact?.['image']) && (
                <img
                  className="rounded-full h-28 sm:h-auto  w-28 ml-auto mr-0 "
                  src={contact?.image?.image?.asset?.url || contact?.['image']}
                  alt={contact?.image?.alt || contact?.['imageAlt']}
                />
              )) || (
                <div className="flex items-center text-center rounded-full h-28 w-28 ml-auto bg-beige">
                  <span className="w-full uppercase text-5xl text-white">
                    {`${contactFirstName.charAt(0)}${contactLastName.charAt(
                      0
                    )}`}
                  </span>
                </div>
              )}
            </span>
          </div>
        </div>
        <div className="w-full mb-4 pt-10 text-center md:w-2/12 md:py-0 text-sm">
          <BioButton
            style="isSmall isWide"
            button={{
              buttonCta: 'Bewerben',
              customPath: `https://karriere.dennree.de/karriere/#/~/application/${vacancyId}/`
            }}
            isExternalLink
          />
        </div>
      </div>
    </li>
  )
}

const getTypesOfEmployment = vacancies => {
  let types = []
  vacancies.forEach(vacancy => {
    vacancy.typeOfEmployment.forEach(type => {
      if (!types.includes(type)) types.push(type)
    })
  })
  return types.join(', ')
}

export default CareerPage

export const query = graphql`
  query ($jobTypeId: String!, $language: String! = "de_de") {
    sanityJobType(_id: { eq: $jobTypeId }) {
      id: _id
      title
      altTitle
      introductionText
      image {
        ...ImageObjectQuery
      }
      areaOfOperations {
        title
      }
      _rawAreaOfOperations
      _rawTask
      _rawProfile
      _rawOffering
      _rawDescription
    }
    sanityVacancies: allSanityVacancy(
      filter: { hidden: { ne: true }, jobType: { _id: { eq: $jobTypeId } } }
    ) {
      totalCount
      nodes {
        title
        typeOfEmployment
        contact {
          image {
            ...ImageObjectQuery
          }
          firstName
          lastName
          phone
          email
        }
        market {
          ... on SanityBranch {
            name
            address {
              street
              city
              zip
            }
          }
          ... on SanityMarket {
            name
            address {
              street
              city
              zip
            }
          }
        }
        jobType {
          _id
        }
      }
    }
    sanityCareerPage: allSanityCareerPage(
      filter: {
        i18n_lang: { eq: $language }
        isNotAvailableInCountry: { ne: true }
      }
    ) {
      nodes {
        i18n_lang
        filterButtonText
        _rawNoSearchResultsText
        applicationCardHeadline
        applicationCardText
      }
    }
  }
`
